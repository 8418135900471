/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { AiOutlineMail, AiOutlineRead } from "react-icons/ai"

const getEmail = () => "zip@zipfreeman.com"

const Bio = () => {
    const data = useStaticQuery(graphql`
        query BioQuery {
            avatar: file(absolutePath: { regex: "/instagram_logo_bw.png/" }) {
                childImageSharp {
                    fixed(width: 30, height: 30, quality: 95) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const [emailState, setEmail] = useState("Show email")

    const avatar = data?.avatar?.childImageSharp?.fixed

    return (
        <div className="bio">
            <a
                href={`https://instagram.com/zipfreemancomics`}
                style={{ display: "flex" }}
            >
                {avatar && <Image fixed={avatar} alt={"insta-icon"} />}
                <p style={{ marginLeft: "4px" }}>Instagram</p>
            </a>

            <a
                href={`https://www.reddit.com/user/zipfreeman/?sort=top`}
                title="Best of (according to Reddit)"
                style={{ display: "flex" }}
            >
                <img
                    src={"/img/reddit-icon-bw.png"}
                    alt={"reddit-icon"}
                    height={"30px"}
                />
                <p style={{ marginLeft: "4px" }}>Reddit (top posts)</p>
            </a>

            <div
                style={{
                    display: "flex",
                    cursor: emailState.search("@") > -1 ? "text" : "pointer",
                }}
                onClick={() => {
                    const email = getEmail()
                    setEmail(email)
                }}
            >
                <AiOutlineMail
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        width: "30px",
                        height: "30px",
                    }}
                />
                <p style={{ marginLeft: "2px" }}>{emailState}</p>
            </div>

            <a
                href={`https://zipfreeman.bigcartel.com`}
                title="come in to my lil bookstore"
                style={{ display: "flex" }}
            >
                <AiOutlineRead
                    style={{
                        backgroundColor: "white",
                        color: "black",
                        width: "30px",
                        height: "30px",
                    }}
                />
                <p style={{ marginLeft: "4px" }}>Buy a zine</p>
            </a>
        </div>
    )
}

export default Bio
